import { queryDataProps } from "src/interfaces";
import Lucide from "src/base-components/Lucide";
import moment from "moment";
import Card from "src/components/Card";
import EmergencyIcon from "./EmergencyIcon";
import { useAppSelector } from "src/stores";
import { selectAuthUser } from "src/stores/auth";
import {
  dateParser,
  getColorFromStatus,
  getTableColorFromStatus,
} from "src/helpers";

function GridViewItem(props: {
  item: queryDataProps;
  index: number;
  onItemClick?: (item: queryDataProps, index?: number) => void;
}) {
  const { item, index, onItemClick } = props;
  const authUser = useAppSelector(selectAuthUser);

  return (
    <Card
      key={index}
      onClick={() => {
        onItemClick && onItemClick(item, index);
      }}
      className={`w-full p-5 ${onItemClick ? "cursor-pointer" : ""} ${
        item?.stage == 4
          ? getColorFromStatus[16]
          : item?.stage == 2
          ? item?.assigned_by && !item?.assigned_to
            ? getColorFromStatus[19]
            : getColorFromStatus[18]
          : item?.stage == 5
          ? getColorFromStatus[20]
          : getColorFromStatus[item.status]
      }`}
    >
      <div className="flex justify-between mb-3">
        <div className="text-[#075985] flex items-center">
          <div
            className={`h-4 w-1 mr-1 ${
              getTableColorFromStatus[
                item?.stage == 4
                  ? 16
                  : item?.stage == 2
                  ? item?.assigned_by && !item?.assigned_to
                    ? 19
                    : 18
                  : item?.stage == 5
                  ? 20
                  : item.status
              ]
            }`}
          />
          {item?.query_no}{" "}
          {item?.is_emergency == 1 && <EmergencyIcon className="mr-0 ml-1" />}
        </div>
        <div className="flex flex-row gap-2">
          {item?.status !== 22 && (
            <span
              className={`h-auto w-auto ml-2.5 px-2 py-0.5  border-slate-200   text-xs rounded-md border  ${
                item.stage == 3
                  ? "text-[#44833A] bg-[#DFF8ED] "
                  : "text-slate-500 bg-slate-100 "
              }`}
            >
              {item?.stage_text}
            </span>
          )}
          {item.status_text !== item?.stage_text && (
            <span
              className={`flex flex-row w-auto h-auto px-2 py-0.5 ml-1  justify-items-stretch text-start text-xs border border-[rgba(48, 48, 48, 0.1)]  rounded-md  overflow-hidden ${
                item.status_text === "Cancelled"
                  ? "bg-[#F5CECC] text-[#CE3B3B]"
                  : item?.status === 22
                  ? "text-slate-500 bg-slate-100 "
                  : "bg-[#DFF8ED] text-[#44833A]"
              }`}
            >
              {item.status_text}
            </span>
          )}
        </div>
      </div>
      <div className="flex justify-between border-b-2 border-dashed dark:bg-darkmode-600 pb-5">
        <div className="flex flex-col">
          <div className="text-[12px]">{item?.customer?.fullname}</div>
          <div className="text-[10px]" style={{ lineHeight: "1rem" }}>
            Customer ID: {item?.customer?.customer_id || ""}
          </div>
        </div>
        <div className="flex items-end text-[10px]">
          No. of PAX:{" "}
          {item.no_of_adults + item?.no_of_children + item?.no_of_infants}
        </div>
      </div>
      <div className="flex flex-col pt-5 gap-4">
        <div className="flex justify-between">
          <div className=" flex-col">
            <div className="text-[10px] font-medium text-[#3C7EA1]">
              Source:
            </div>
            <div className="flex items-center gap-2 text-[12px">
              <Lucide
                icon={
                  item?.source == "Phone"
                    ? "Phone"
                    : item?.source == "Website"
                    ? "Mail"
                    : "Shield"
                }
                color="#3C7EA1"
                className="w-4 h-4"
              />
              <div className="text-[12px]">{item?.source}</div>
            </div>
          </div>

          <div className=" flex-col ">
            <div className="text-[10px] font-medium text-[#3C7EA1] text-end">
              Location:
            </div>
            <div className="flex items-center gap-2 text-[12px">
              <div className="text-[12px]">
                {item?.sourced_by_user?.location?.name ?? item?.location?.name}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex flex-col leading-4">
            <div className="text-[10px] font-medium text-[#075985] ">
              Dated:
            </div>
            <div className="text-[12px]">
              {dateParser(item.created_at, "DD-MM-YYYY")}
            </div>
          </div>
          <div className="flex flex-col leading-4">
            <div className="text-[10px] font-medium text-[#075985] text-end ">
              Last Updated On:
            </div>
            <div className="text-[12px] text-right">
              {dateParser(item.updated_at, "DD-MM-YYYY")}
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex flex-col leading-4">
            <div className="text-[10px] font-medium text-[#075985] ">
              Created By:
            </div>
            <div className="text-[12px]">
              {item?.sourced_by == authUser.id
                ? "You"
                : `${item?.sourced_by_user?.name || ""} ${
                    item?.sourced_by_user?.last_name || ""
                  }`}
            </div>
          </div>
          <div className="flex flex-col leading-4">
            <div className="text-[10px] font-medium text-[#075985] text-end ">
              Assigned To:
            </div>
            <div className="text-[12px] text-right">
              {item?.assigned_to_user?.name
                ? `${item?.assigned_to_user?.name} ${
                    item?.assigned_to_user?.last_name
                      ? item?.assigned_to_user?.last_name
                      : ""
                  }`
                : `N/A`}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default GridViewItem;
