export const isQueryClosedAndCancelled = (status: any = 0) =>
  parseInt(status) == 9 || parseInt(status) == 14 || parseInt(status) == 22;
export const isQueryClosed = (status: any = 0) => parseInt(status) >= 9;
export const isQueryOpen = (stage: any = 0) =>
  parseInt(stage) < 3 || parseInt(stage) == 5;
export const isQueryOpenOrReopened = (stage: any = 0) => parseInt(stage) != 3;
export const isQueryCancelled = (status: any = 0) => parseInt(status) == 14;

export const isMultipleAcceptedQuotation = (
  payments: any,
  acceptedQuotations: any
) => {
  try {
    if (!Array.isArray(acceptedQuotations)) {
      acceptedQuotations = [acceptedQuotations];
    }
    if (!Array.isArray(payments)) {
      payments = [payments];
    }

    const acceptedItems = acceptedQuotations.filter(
      (item: any) => item.status_text === "Accepted"
    );

    if (acceptedItems.some((quotation) => quotation.is_partial !== 0)) {
      return false;
    }

    const paymentQuotationIds = new Set(
      payments.map((payment) => payment.quotation_id)
    );

    return acceptedItems.every((quotation) =>
      paymentQuotationIds.has(quotation.id)
    );
  } catch (error) {
    console.log("Error in isMultipleAcceptedQuotation: ", error);
    return false;
  }
};
